@import '../../../utils/variables';

.container {
    position: relative;
    width: auto;
    min-height: 70rem;
    margin: 0 2rem 6rem;
}
.link {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.img {
    position: absolute;
    width: 100%;
    height: 100%;
}
.imgInner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, 0.2);
        z-index: 1;
    }
}
.imgInside {
}
.content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 52rem;
    margin: 20.6rem 0 0;
    z-index: 1;
}
.contentTitle {
    margin: 0 0 2.15rem;
    color: $white;
    font-size: 3rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.145rem;
}
.contentText {
    margin: 0 0 3.45rem;
    color: $white;
    font-size: 1.3rem;
    line-height: 2;
    letter-spacing: 0.065rem;
}
.contentLink {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 25rem;
    min-height: 6rem;
    padding: 2.15rem;
    border: 1px solid $white;
    // border-radius: 3rem;
    border-radius: 4rem;
}
.linkText {
    color: $white;
    font-size: 1.3rem;
    font-family: $gilroySemi;
    letter-spacing: 0.1rem;
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        min-height: 60rem;
        margin: 0 0.9rem 5.05rem;
    }
    .content {
        width: 30rem;
        margin: 18rem 0 0;
    }
    .contentText {
        margin: 0 0 2.55rem;
        line-height: 1.7;
    }
    .contentLink {
        min-width: 18rem;
        min-height: 4rem;
        padding: 1.15rem;
    }
    .linkText {
        font-size: 1.1rem;
    }
}
