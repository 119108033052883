@import '../../../utils/variables';

.container {
    width: 156rem;
    margin: 0 auto 12.35rem;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 96rem;
    margin: 0 auto 5.65rem;
}
.headerTitle {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: $key;
    font-size: 3rem;
    font-family: $gilroyExtra;
    font-weight: $bold;
    letter-spacing: 0.15rem;
    &::after {
        content: '';
        width: 100%;
        height: 1px;
        margin: 0 4rem;
        flex-grow: 1;
        flex-basis: 0;
        background-color: $key;
    }
}
.headerMore {
    margin: 0 -1rem;
    padding: 1rem;
    color: $key;
    font-size: 1.6rem;
    font-family: $gilroyExtra;
    font-weight: $bold;
    letter-spacing: 0.115rem;
}
.headerMoreText {
    margin: 0 0 0 0.85rem;
}
.bodyList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.bodyItem {
    position: relative;
    margin: 0 2.15rem 2.15rem 0;
    &:nth-child(3n) {
        margin: 0 0 2.15rem;
    }
}
.bodyItemCategory {
    position: absolute;
    top: 2rem;
    left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 8rem;
    min-height: 4rem;
    padding: 1.25rem;
    background-color: $white;
    border-radius: 2rem;
    z-index: 1;
}
.bodyItemCategoryText {
    color: $key;
    font-size: 1.3rem;
    font-family: $gilroySemi;
}
.bodyItemLink {
    width: 100%;
    height: 100%;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
    }
}
.bodyItemImg {
    position: relative;
    width: 50.55rem;
    height: 37.9125rem;
    margin: 0 0 3.5rem;
    overflow: hidden;
    img {
        height: 50.55rem;
    }
}
.bodyItemContent {
    width: 35rem;
}
.bodyItemContentTitle {
    margin: 0 0 1.6rem;
    color: $key;
    font-size: 1.6rem;
    font-family: $gilroyExtra;
    font-weight: $bold;
    letter-spacing: 0.155rem;
}
.bodyItemContentText {
    margin: 0 0 2rem;
    color: $key;
    font-size: 1.3rem;
    line-height: 2;
    letter-spacing: 0.07rem;
}
.bodyItemContentLink {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 12rem;
    min-height: 4rem;
    padding: 1.35rem;
    background-color: $key_sub;
    border-radius: 2rem;
}
.bodyItemContentLinkText {
    color: $white;
    font-size: 1.1rem;
    font-weight: $semi;
    font-family: $gilroySemi;
    letter-spacing: 0.115rem;
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        width: auto;
        margin: 0 0.9rem 5.75rem;
    }
    .header {
        align-items: flex-end;
        width: 30rem;
        margin: 0px auto 2.15rem;
    }
    .headerTitle {
        width: auto;
        font-size: 3rem;
        letter-spacing: 0.15rem;
        &::after {
            content: none;
        }
    }
    .headerMore {
        margin: 0 -1rem -0.5rem 0;
        font-size: 1.1rem;
    }
    .headerMoreText {
        margin: 0 0 0 0.5rem;
    }
    .bodyItem {
        width: 100%;
        margin: 0 0 5.15rem;
    }
    .bodyItemCategory {
        min-width: 6rem;
        min-height: 3rem;
        padding: 0.85rem 2rem;
    }
    .bodyItemCategoryText {
        font-size: 1.1rem;
    }
    .bodyItemImg {
        width: 100%;
        height: 26.8125rem;
        margin: 0 0 3rem;
        img {
            height: 35.75rem;
        }
    }
    .bodyItemContent {
        width: 30rem;
        margin: 0 auto;
    }
    .bodyItemContentTitle {
        margin: 0 0 1.1rem;
        font-size: 1.6rem;
    }
    .bodyItemContentText {
        line-height: 1.7;
    }
    .bodyItemContentLinkText {
        font-size: 1.1rem;
    }
}
