@import '../../utils/variables';

.order {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 4rem;
    padding: 1.35rem 2.2rem;
    border-radius: 4rem;
    background-color: $key_sub;
    z-index: 1;
}
.orderText {
    color: $white;
    font-family: $gilroySemi;
    font-weight: $semi;
    font-size: 1.1rem;
    letter-spacing: 0.115rem;
}
.orderTextParent {
    position: relative;
    display: inline-flex;
}
.orderTextParentInnerWord {
    display: inline-flex;
}
.orderTextParentInnerMark {
    display: inline-flex;
}
.orderTextWord {
    display: inline-block;
}

//
//
//

@media screen and (max-width: $sp) {
    .order {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-height: 4rem;
        background-color: $key_sub;
    }
    .orderText {
        color: $white;
        font-size: 1.1rem;
        font-weight: $semi;
        font-family: $gilroySemi;
        letter-spacing: 0.115rem;
    }
}
