@import '../../../utils/variables';

.container {
    width: auto;
    height: 100%;
    margin: 0 2rem 12.55rem;
}
.swiper {
    width: auto;
    margin: 0 0 2rem;
}
.swiperItem {
    position: relative;
    overflow: hidden;
}
.swiperItemImg {
    position: relative;
    width: 120%;
    height: 85rem;
    margin: 0 0 0 -10%;
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 50%;
        background: linear-gradient(
            180deg,
            rgba(14, 35, 47, 0) 0%,
            #0e232f 100%
        );
        opacity: 0.3;
        z-index: 1;
    }
}
.swiperItemImgInside {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiperItemSection {
    position: absolute;
    bottom: 5rem;
    left: 10rem;
    width: calc(100% - 20rem);
    z-index: 1;
}
.swiperItemSectionTitle {
    margin: 0 0 2rem;
    color: $white;
    font-size: 10rem;
    font-family: $gilroyExtra;
    font-weight: $bold;
    line-height: 1;
    letter-spacing: 0.3rem;
}
.swiperItemSectionText {
    width: 47rem;
    margin: 0 0 3rem;
    color: $white;
    font-size: 1.3rem;
    font-weight: $bold;
    line-height: 2;
    letter-spacing: 0.1rem;
}
.swiperItemSectionLink {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 12rem;
    min-height: 4rem;
    padding: 1.35rem;
    background-color: $white;
    border-radius: 4rem;
}
.swiperItemSectionLinkText {
    color: $key_sub;
    font-size: 1.1rem;
    font-family: $gilroySemi;
    letter-spacing: 0.115rem;
}
.banner {
    width: auto;
}
.bannerList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.bannerItem {
    width: 37.5rem;
    height: 37.5rem;
    margin: 0 2rem 2rem 0;
    overflow: hidden;
}
.bannerLink {
    position: relative;
    display: block;
    width: 37.5rem;
    height: 37.5rem;
}
.bannerImg {
    width: 100%;
    height: 100%;
}
.bannerImgInside {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

//
//
//

@media screen and (min-width: $pc) {
    .bannerItem {
        width: calc(25vw - 2.5rem);
        height: calc(25vw - 2.5rem);
    }
    .bannerLink {
        width: calc(25vw - 2.5rem);
        height: calc(25vw - 2.5rem);
    }
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        margin: 0 0 7.15rem;
    }
    .swiper {
        margin: 0 0 5rem;
    }
    .swiperItemImgCover {
        height: 37.5rem;
        margin: 0 0 3.05rem;
        overflow: hidden;
    }
    .swiperItemImg {
        height: 37.5rem;
    }
    .swiperItemSection {
        position: static;
        width: 30rem;
        margin: 0 auto;
    }
    .swiperItemSectionTitle {
        margin: 0 0 1rem;
        color: $key;
        font-size: 4rem;
        letter-spacing: 0.235rem;
    }
    .swiperItemSectionText {
        width: 100%;
        margin: 0 0 2rem;
        color: $key;
        font-size: 1.3rem;
        line-height: 1.7;
        letter-spacing: 0.085rem;
    }
    .swiperItemSectionLink {
        padding: 1.35rem 2.2rem;
        background-color: $key_sub;
    }
    .swiperItemSectionLinkText {
        color: $white;
        letter-spacing: 0.135rem;
    }
    .banner {
        margin: 0 0.9rem;
    }
    .bannerItem {
        width: 17.35rem;
        height: 17.35rem;
        margin: 0 1rem 1rem 0;
        &:nth-child(2n) {
            margin: 0;
        }
    }
    .bannerLink {
        width: 17.35rem;
        height: 17.35rem;
    }
}
