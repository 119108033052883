@import '../../../utils/variables';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-height: 6rem;
    margin: 0 2rem;
    background-color: $key;
}
.swiper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 3rem;
}
.swiperList {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.swiperLink {
    display: block;
    width: fit-content;
    margin: 0 auto;
    padding: 1rem;
    color: $white;
    font-size: 1.3rem;
    letter-spacing: 0.055rem;
    text-align: center;
}
.swiperArrow {
    position: absolute;
    width: 4rem;
    height: 4rem;
    background-color: $key;
    z-index: 11;
}
.swiperArrowNext {
    top: 0;
    right: 0;
}
.swiperArrowPrev {
    top: 0;
    left: 0;
    transform: rotate(180deg);
}
.swiperArrowSvg {
    width: 1.2rem;
    height: 1.2rem;
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        margin: 0;
    }
    .swiper {
        justify-content: center;
        padding: 0;
    }
    .swiperList {
        width: 100%;
    }
    .swiperItem {
        width: 23rem;
    }
    .swiperLink {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 1rem;
    }
    .swiperArrow {
        display: none;
    }
}
