@import '../../../utils/variables';

.container {
    position: relative;
    width: auto;
    min-height: 80rem;
    margin: 0 2rem 17rem;
}
.link {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.img {
    position: absolute;
    width: 100%;
    height: 100%;
}
.imgInner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0, 0.4);
        z-index: 1;
    }
}
.content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6rem 0 0;
    z-index: 1;
}
.contentSvg {
    width: 6rem;
    height: 7rem;
    margin: 0 0 2.25rem;
}
.contentTitle {
    margin: 0 0 2.25rem;
    color: $white;
    font-size: 7rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.35rem;
}
.contentLink {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 25rem;
    min-height: 6rem;
    padding: 2.15rem;
    border: 1px solid $white;
    border-radius: 4rem;
}
.contentLinkText {
    color: $white;
    font-size: 1.3rem;
    font-family: $gilroySemi;
    letter-spacing: 0.1rem;
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        min-height: 35.65rem;
        margin: 0 0.9rem 8rem;
    }
    .content {
        margin: 3rem 0 0;
    }
    .contentSvg {
        width: 3rem;
        height: 3.5rem;
        margin: 0 0 1.05rem;
    }
    .contentTitle {
        margin: 0 0 1.25rem;
        font-size: 3rem;
        letter-spacing: 0.175rem;
    }
    .contentLink {
        min-width: 18rem;
        min-height: 4rem;
        padding: 1.15rem;
    }
    .contentLinkText {
        font-size: 1.1rem;
        letter-spacing: 0.125rem;
    }
}
