@import '../../../utils/variables';

.container {
    width: 156rem;
    margin: 0 auto 7rem;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 96rem;
    margin: 0 auto 5.65rem;
}
.headerTitle {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 83rem;
    color: $key;
    font-size: 3rem;
    font-family: $gilroyExtra;
    font-weight: $bold;
    letter-spacing: 0.15rem;
    &::after {
        content: '';
        width: 100%;
        height: 1px;
        margin: 0 4rem;
        flex-grow: 1;
        flex-basis: 0;
        background-color: $key;
    }
}
.headerMore {
    margin: 0 -1rem;
    padding: 1rem;
    color: $key;
    font-size: 1.6rem;
    font-family: $gilroyExtra;
    font-weight: $bold;
    letter-spacing: 0.115rem;
}
.headerMoreText {
    margin: 0 0 0 0.85rem;
}
.bodyList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.bodyItem {
    position: relative;
    display: block;
    margin: 0 2rem 8rem 0;
    &:nth-child(4n) {
        margin: 0 0 8rem;
    }
}
.bodyLink {
    display: block;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
    }
}
.bodyItemImg {
    position: relative;
    width: 37.5rem;
    height: 37.5rem;
    margin: 0 0 6rem;
}
.bodyItemContent {
    width: 30.5rem;
}
.bodyItemContentTitle {
    margin: 0 0 1.6rem;
    color: $key;
    font-size: 1.6rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.16rem;
}
.bodyItemContentText {
    margin: 0 0 2rem;
    color: $key;
    font-size: 1.3rem;
    line-height: 2;
    letter-spacing: 0.07rem;
}
.bodyItemContentPrice {
    color: $key;
    font-size: 1.6rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.16rem;
}
.bodyItemContentPrice + a {
    margin: 1.6rem 0 0;
}
.bodyItemContentLink {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 12rem;
    min-height: 4rem;
    padding: 1.35rem;
    background-color: $key_sub;
    border-radius: 2rem;
}
.bodyItemContentLinkText {
    color: $white;
    font-size: 1.1rem;
    font-family: $gilroySemi;
    letter-spacing: 0.115rem;
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        width: auto;
        margin: 0 0.9rem 5rem;
    }
    .header {
        align-items: flex-end;
        width: 30rem;
        margin: 0px auto 2rem;
    }
    .headerTitle {
        width: auto;
        font-size: 3rem;
        letter-spacing: 0.15rem;
        line-height: 1.375;
        &::after {
            content: none;
        }
    }
    .headerMore {
        margin: 0 -1rem -0.5rem 0;
        font-size: 1.1rem;
        line-height: 1.65;
    }
    .headerMoreText {
        margin: 0 0 0 0.5rem;
    }
    .bodyItem {
        margin: 0 0.95rem 5.05rem 0;
        &:nth-child(2n) {
            margin: 0 0 5.05rem;
        }
    }
    .bodyItemImg {
        width: 17.35rem;
        height: 17.35rem;
        margin: 0 0 2.5rem;
    }
    .bodyItemContent {
        width: 16.65rem;
    }
    .bodyItemContentTitle {
        margin: 0 0 0.9rem;
        line-height: 1.25;
        letter-spacing: 0.16rem;
    }
    .bodyItemContentText {
        margin: 0 0 1rem;
        font-size: 1.1rem;
        line-height: 1.7;
        letter-spacing: 0.205rem;
    }
}
