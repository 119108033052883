@import '../../../utils/variables';

.container {
    width: auto;
    margin: 0 2rem 10rem;
}
.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.item {
    display: block;
    margin: 0 2rem 2rem 0;
    &:nth-child(2n) {
        margin: 0;
    }
}
.itemSection {
    display: block;
}
.link {
    display: block;
}
.itemImg {
    position: relative;
    width: 77rem;
    height: 50rem;
    margin: 0 0 3.5rem;
    overflow: hidden;
}
.itemContent {
    width: 37rem;
    margin: 0 0 0 4rem;
}
.itemContentTitle {
    display: flex;
    align-items: center;
    margin: 0 0 1.6rem;
}
.itemContentTitleInner {
    margin: 0 1rem 0 0;
    color: $key;
    font-size: 1.6rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.16rem;
}
.itemContentText {
    color: $key;
    font-size: 1.3rem;
    line-height: 2;
    letter-spacing: 0.078rem;
}

//
//
//

@media screen and (min-width: $pc) {
    .itemImg {
        width: calc(50vw - 3rem);
    }
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        margin: 0 0.9rem;
    }
    .list {
        flex-direction: column;
    }
    .item {
        margin: 0 0 5rem;
        &:nth-child(2n) {
            margin: 0 0 5rem;
        }
    }
    .itemImg {
        width: 100%;
        height: 23.25rem;
        margin: 0 0 3rem;
    }
    .itemContent {
        width: 30.25rem;
        margin: 0 auto;
    }
    .itemContentTitle {
        margin: 0 0 1.15rem;
    }
    .itemContentTitleInner {
        margin: 0 0.85rem 0 0;
        font-size: 1.6rem;
        letter-spacing: 0.17rem;
    }
    .itemContentText {
        font-size: 1.3rem;
        line-height: 1.7;
    }
}
